.ganttTable_Header_custom {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 12px;
  background-color: #f5f5f5;
  color: #000000;
  font-weight: bold;
}

.gantt-container > div > div[dir='ltr'] {
  overflow-x: scroll !important;
  scrollbar-width: auto;
}

.ganttTable_TBody-row {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
}

.ganttTable_TBody-row.gisement-row {
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-left: 10px;
}

.ganttTable_TBody-row.regle-row {
  padding-left: 20px;
  border: none;
  border-bottom: 1px solid rgba(221, 221, 221, 0.24);
}

.expander {
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.expander.open {
  transform: rotate(0deg);
}

.expander.closed {
  transform: rotate(0deg);
}

.bar > g > g > rect:last-of-type {
  height: 30px !important;
}
